import { Loading, QSpinnerComment, Dark } from 'quasar'
import { usePermission } from "@/composables/permission";

export default async function permissionMiddleware({ to, next }) {
  Loading.show({
    spinner: QSpinnerComment,
    backgroundColor: Dark.isActive ? 'dark' : 'white',
    spinnerColor: 'primary',
    spinnerSize: 140,
    messageColor: Dark.isActive ? 'white' : 'dark',
    message: '<div class="text-h5 text-uppercase">Welcome to <b>Chat Your Job</b></div><br/><div class="text-subtitle2 ">Powered by <b>DEKRA</b></div><br/><span class="text-italic">Please wait...</span>',
    html: true,
  })
  const { userCan } = usePermission();
  const hasPermission = userCan(to.meta.requiredPermission);
  if (!hasPermission) {
    Loading.hide()
    return next('/access-denied');
  }
  Loading.hide()
  return next();
}
